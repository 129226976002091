<template>
  <div class="403-container">
    <errorPage
      :title="$t('errorPages.title403')"
      :headline="$t('errorPages.headline403')"
      :message="$t('errorPages.message403')"
    />
  </div>
</template>

<script>
import errorPage from './errorPage.vue';

export default {
  name: 'Page403',
  components: {
    errorPage
  },
  data() {
    return {};
  }
};
</script>
